<template>
  <Table>
    <template #thead>
      <TableRow>
        <TableData heading text-align="left">
          {{ $t('rewards.tableHeadTournament') }}
        </TableData>
        <TableData heading>
          {{ $t('rewards.tableHash') }}
        </TableData>
        <TableData heading>
          {{ $t('rewards.tableHeadDates') }}
        </TableData>
        <TableData heading>
          {{ $t('rewards.tableHeadPlace') }}
        </TableData>
        <TableData heading>
          {{ $t('rewards.tableHeadReward') }}
        </TableData>
        <TableData heading>
          {{ $t('rewards.tableHeadStatus') }}
        </TableData>
      </TableRow>
    </template>
    <template #tbody>
      <TableRow v-for="(row, rowIndex) in tableData" :key="rowIndex">
        <TableData text-align="left">
          <RewardsTournament
            :tournament-id="row.tournament.id"
            :tournament-name="row.tournament.name"
            :game-id="row.game.id"
          />
        </TableData>
        <TableData>
          <p>{{ row.payout.id }}</p>
          <p>{{ row.payout.vkId }}</p>
        </TableData>
        <TableData>
          {{ createDatetime(row.tournament.startTs) }} -
          {{ createDatetime(row.tournament.finishTs) }}
        </TableData>
        <TableData>
          {{ row.tournament.place }}
        </TableData>
        <TableData>{{ row.payout.amount }} ₽</TableData>
        <TableData white-space="normal">
          <RewardsStatus
            :status="row.payout.status"
            :is-payable="isPayable"
          />
        </TableData>
      </TableRow>
    </template>
  </Table>
</template>

<script>
import RewardsTournament from './RewardsTournament.vue';
import RewardsStatus from './RewardsStatus.vue';
import Table from '@components/v2/ui/Table.vue';
import TableRow from '@components/v2/ui/TableRow.vue';
import TableData from '@components/v2/ui/TableData.vue';
import { createDatetimeString } from '../lib/datetime';

export default {
  name: 'RewardsTable',
  components: {
    RewardsTournament,
    RewardsStatus,
    Table,
    TableRow,
    TableData,
  },

  props: {
    isPayable: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      table: null,
    };
  },

  methods: {
    createDatetime(timestamp) {
      return createDatetimeString(timestamp);
    },
  },
};
</script>

<style lang="scss" scoped>
td {
  vertical-align: top;
  line-height: 18px;
}
</style>
