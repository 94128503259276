<template>
  <loader v-if="isLoading" />
  <div v-else class="rewards">
    <h2 class="rewards__title">
      {{ $t('rewards.allRewards') }}
    </h2>
    <AllRewards
      :is-payable="isPayable"
      :paid-amount="summary.paidTotalAmount"
      :pending-amount="summary.pendingTotalAmount"
    />

    <h2 class="rewards__title">
      {{ $t('rewards.rewardsData') }}
    </h2>
    <RewardsData
      :vk-id="vkIdStatus.vkId"
      :trust-doc-status="vkIdStatus.trustDocStatus"
      :vk-pay-status="vkIdStatus.vkPayStatus"
    />

    <h2 class="rewards__title">{{ $t('rewards.rewardsTable') }}</h2>
    <RewardsTable
      v-if="payments.length"
      :table-data="payments"
      :is-payable="vkIdStatus.isPayable"
    />
    <p v-else class="rewards__placeholder">
      {{ $t('rewards.rewardsTablePlaceholder') }}
    </p>
  </div>
</template>

<script>
import RewardsTable from './ui/RewardsTable.vue';
import AllRewards from './ui/AllRewards.vue';
import RewardsData from './ui/RewardsData.vue';

import {
  fetchProfilePayoutSummary,
  fetchProfilePayoutVkIdStatus,
  fetchProfilePayout,
  fetchRefreshStatus,
} from './api';

export default {
  name: 'Rewards',
  components: {
    AllRewards,
    RewardsData,
    RewardsTable,
  },

  data() {
    return {
      isLoading: true,
      summary: null,
      vkIdStatus: null,
      payments: [],
    };
  },

  computed: {
    isPayable() {
      return this.vkIdStatus?.isPayable;
    },
  },

  async created() {
    window.addEventListener(
      'visibilitychange',
      this.handleRefreshStatus,
    );

    const [summary, vkIdStatus, payments] = await Promise.all([
      fetchProfilePayoutSummary(),
      fetchProfilePayoutVkIdStatus(),
      fetchProfilePayout(),
    ]);

    this.summary = summary;
    this.vkIdStatus = vkIdStatus;
    this.payments = payments;
    this.isLoading = false;
  },

  mounted() {
    this.refreshStatus()
  },

  beforeDestroy() {
    window.removeEventListener(
      'visibilitychange',
      this.handleRefreshStatus,
    );
  },

  methods: {
    handleRefreshStatus() {
      if (document.visibilityState === 'visible') {
        this.refreshStatus();
      }
    },

    refreshStatus() {
      fetchRefreshStatus()
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err))
        .finally(() => fetchProfilePayoutVkIdStatus());
    },
  },
};
</script>

<style lang="scss" scoped>
.rewards {
  display: block;
}
.rewards__title {
  color: #fff;
  font-size: 18px;
  margin-bottom: 12px;
}
.rewards__placeholder {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $dark-background;
  border-radius: 3px;
  box-shadow: $default-box-shadow;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #979797;

  @media screen and (min-width: 768px) {
    padding: 30px 12px;
  }
}
</style>
