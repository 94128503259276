<template>
  <div class="rewards-status" :class="rewardsStatusComputedClass">
    <p class="rewards-status__text" :class="textComputedClass">
      {{ text }}
    </p>
    <p v-if="isShowDescription">
      {{ $t('rewards.rewardsStatusDescription') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'RewardsStatus',

  props: {
    status: {
      type: String,
      default: '',
    },
    isPayable: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isPending() {
      return this.status === 'pending';
    },
    isShowDescription() {
      return this.isPending && !this.isPayable;
    },
    text() {
      switch (this.status) {
        case 'pending':
          return this.$t('rewards.rewardsStatusProcessing');
        case 'decline':
          return this.$t('rewards.rewardsStatusDeclined');
        case 'paid':
          return this.$t('rewards.rewardsStatusPaid');

        default:
          return this.$t('rewards.noData');
      }
    },
    rewardsStatusComputedClass() {
      return {
        'rewards-status--align-left': this.isShowDescription,
      };
    },
    textComputedClass() {
      return `rewards-status__text--${this.status}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.rewards-status {
  display: inline-block;

  &--align-left {
    text-align: left;
  }
}

.rewards-status__text {
  margin-bottom: 6px;

  &--pending {
    color: #16a7ff;
  }
  &--decline {
    color: #c64040;
  }
  &--paid {
    color: #65cb6d;
  }
}

.rewards-status__date {
  color: #c64040;
}
</style>
