<template>
  <div class="all-rewards">
    <RewardsCard
      :title="$t('rewards.allTimeRewards')"
      :subtitle="allTimeRewardsSubtitle"
    />
    <RewardsCard
      :title="$t('rewards.pendingRewards')"
      :subtitle="pendingRewardsSubtitle"
      :warning="warningText"
    >
      <Button
        v-if="isShowEditDataButton"
        tag="router-link"
        size="big"
        type="primary"
        :text="$t('rewards.editDataButton')"
        to="/autopayments"
        long
      />
    </RewardsCard>
  </div>
</template>

<script>
import RewardsCard from './RewardsCard.vue';
import Button from '@components/v2/ui/Button.vue';

export default {
  name: 'AllRewards',
  components: {
    RewardsCard,
    Button,
  },

  props: {
    isPayable: {
      type: Boolean,
      default: true,
    },
    paidAmount: {
      type: String,
      default: '0',
    },
    pendingAmount: {
      type: String,
      default: '0',
    },
  },

  computed: {
    isShowEditDataButton() {
      return !this.isPayable;
    },
    allTimeRewardsSubtitle() {
      return `${this.paidAmount} ₽`;
    },
    pendingRewardsSubtitle() {
      return `${this.pendingAmount} ₽`;
    },
    warningText() {
      return this.isPayable
        ? ''
        : `${this.$t('rewards.pendingWarning')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.all-rewards {
  display: grid;
  gap: 16px;
  margin-bottom: 24px;

  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
